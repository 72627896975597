import { onAuthStateChanged } from 'firebase/auth';
import { arrayUnion, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { auth, db, storage } from '../../../firebase.config';
import { setDocument, updateDocument } from '../../../utils/api';
import { showToast } from '../../../utils/toast';
import { checkFileSize } from '../../../utils/utils';
import { Spinner } from '../../reusable/spinner/Spinner';
import Termsandcondition from "../../reusable/modal/termsandcondition";
import ReactToPrint from 'react-to-print';
import { useNavigate, useParams } from 'react-router-dom';
import { Input } from '../../reusable/input';


const InviteForm = () => {
    const [user, setuser] = useState()
    const [forms, setforms] = useState()
    const [loader, setLoader] = useState(false);
    const [load, setLoad] = useState(true)
    const [showModal, setshowModal] = useState(false);
    const [recall, setrecall] = useState(false);
    const [cheque, setcheque] = useState()
    const [data, setdata] = useState({
        legalName: "",
        address: "",
        contactName: "",
        contactNo: "",
        services: "",
        annualSpend: "",
        isServiceProvided: "",
        note: "",
        isVendorRegistered: "",
        vendorIntroduced: "",
        vendorEngaged: "",
        nameOfAgency: "",
        mediaType: "",
        entityType: "",
        knownVendor: "",
        vendorBefore: "",
        isVendorCapable: "",
        reason: "",
        relatedParty: "",
        requesterName: "",
        requesterSign: "",
        date: "",
    })
    const [bank, setbank] = useState({})
    const [error, seterror] = useState("")
    const navigate = useNavigate();
    const _params = useParams();
    const fileRef = useRef()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setuser(user);
            }
        });
        if (_params == null && _params.id == null) {
            alert("ID not found!");
        }
        console.log("_params.id", _params.id);
    }, []);

    useEffect(() => {
        if (user) {
            onSnapshot(doc(db, "users", user.uid), (doc) => {
                const _tmp = doc.data()
                setforms(_tmp?.forms);
                setbank(_tmp?.bankDetails);
                setdata({
                    beneficiaryName: _tmp?.name,
                    address: _tmp?.address,
                    phone: _tmp?.phone,
                    pancard: _tmp?.pancard,
                    email: _tmp?.email,
                });
            });
            onSnapshot(doc(db, "documents", user.uid), (doc) => {
                const _tmp = doc.data()
                setcheque(_tmp?.cancelledCheque);
            });
            setTimeout(() => setLoad(false), 1500)

        }
    }, [user])

    const handleChange = (e) => {
        if (user) {
            setLoader(true);
            const file = e.target.files[0];
            const metadata = {
                contentType: file.type,
            };

            checkFileSize(file.size);

            const storageRef = ref(storage, `vendors/${user.uid}/documents/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file, metadata);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => console.log(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        setcheque(url);
                        await updateDocument("documents", user.uid, { cancelledCheque: url });
                        setLoader(false);
                    });
                }
            );

        }
    }

    const handleSubmit = async (e) => {
        if (user && user.uid) {
            await updateDoc(doc(db, "users", user.uid), {
                inviteeForm: data
            });
            const currentDate = Date.now();
            const log = {
                title: `${user.displayName} submitted Inviter Form for ${_params?.id}`,
                timestamp: currentDate,
            }
            await updateDoc(doc(db, "users", user.uid), {
                "logs": arrayUnion(log),
            });
            showToast('success', 'Invite Form Saved Successfully');
            navigate('/dashboard');
            return;
        }
    }

    useEffect(() => {
        if (recall === true) {
            handleSubmit();
            setrecall(false);
        }
    }, [recall])

    const printDivRef = useRef();

    return (
        <>
            <div id="maindiv" className='p-4 w-full h-full rounded-lg border-gray-800 glass' style={{ height: load ? "calc(100vh - 80px)" : "100%" }}>
                <div ref={printDivRef} id="printref" className='p-10 pt-0 lg:w-full m-auto align-center rounded-lg border-gray-800 glass'>
                    <h3 className='p-3 text-white text-center h3 border-bottom border-secondary break-all mb-4'>Vendor Invite Form</h3>
                    {load ?
                        <Spinner loader={load} />
                        :
                        <form onSubmit={(e) => { e.preventDefault(); setshowModal(true) }}>
                            <p className="h4 text-white border-bottom pb-2 mb-3">User Details</p>
                            <div className="row">
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="legalName"
                                        label="Legal name"
                                        onChange={(e) => setdata({
                                            ...data,
                                            legalName: e.target.value
                                        })}
                                        value={data?.legalName}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="address"
                                        label="Address"
                                        onChange={(e) => setdata({
                                            ...data,
                                            address: e.target.value
                                        })}
                                        value={data?.address}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="contactName"
                                        label="Contact name/title (of the Vendor)"
                                        onChange={(e) => setdata({
                                            ...data,
                                            contactName: e.target.value
                                        })}
                                        value={data?.contactName}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="contactNo"
                                        label="Contact phone number/email address"
                                        onChange={(e) => setdata({
                                            ...data,
                                            contactNo: e.target.value
                                        })}
                                        value={data?.contactNo}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="services"
                                        label="Services to be provided by Vendor"
                                        onChange={(e) => setdata({
                                            ...data,
                                            services: e.target.value
                                        })}
                                        value={data?.services}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="annualSpend"
                                        label="What is the estimated annual spend with this Vendor?"
                                        onChange={(e) => setdata({
                                            ...data,
                                            annualSpend: e.target.value
                                        })}
                                        value={data?.annualSpend}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <label className="block mb-2 text-sm font-medium text-gray-300">Is there a Omnicom Media Group or Agency Preferred Vendor who could provide these services?</label>
                                    <select
                                        defaultValue=""
                                        onChange={(e) => setdata({
                                            ...data,
                                            isServiceProvided: e.target.value
                                        })}
                                        value={bank?.isServiceProvided}
                                        className="bg-gray-500 shadow-none border-none text-gray-50 text-sm rounded-lg block w-full p-2.5 placeholder-gray-700 disabled:opacity-80"
                                        aria-label="Default select"
                                        required
                                    >
                                        <option selected value="" disabled>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="note"
                                        label="If yes, note reason for requesting this new Vendor below:"
                                        onChange={(e) => setdata({
                                            ...data,
                                            note: e.target.value
                                        })}
                                        value={data?.note}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="isVendorRegistered"
                                        label="Whether the Vendor is registered with Industry Body/Association/Accredited, if yes please specify"
                                        onChange={(e) => setdata({
                                            ...data,
                                            isVendorRegistered: e.target.value
                                        })}
                                        value={data?.isVendorRegistered}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="vendorIntroduced"
                                        label="Is the vendor introduced by Client or Agency?"
                                        onChange={(e) => setdata({
                                            ...data,
                                            vendorIntroduced: e.target.value
                                        })}
                                        value={data?.vendorIntroduced}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="vendorEngaged"
                                        label="Is the vendor to be engaged for the specific client Activity? -Please provide the name of the client"
                                        onChange={(e) => setdata({
                                            ...data,
                                            vendorEngaged: e.target.value
                                        })}
                                        value={data?.vendorEngaged}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="nameOfAgency"
                                        label="Please indicate the name of the agency in which the Vendor to be registered. (i.e OMGIPL or OMGDIPL)"
                                        onChange={(e) => setdata({
                                            ...data,
                                            nameOfAgency: e.target.value
                                        })}
                                        value={data?.nameOfAgency}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="mediaType"
                                        label="Media Type"
                                        onChange={(e) => setdata({
                                            ...data,
                                            mediaType: e.target.value
                                        })}
                                        value={data?.mediaType}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="entityType"
                                        label="Please indicate the name of the agency in which the Vendor to be registered. (i.e OMGIPL or OMGDIPL)"
                                        onChange={(e) => setdata({
                                            ...data,
                                            entityType: e.target.value
                                        })}
                                        value={data?.entityType}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="knownVendor"
                                        label="How did you learn about this Vendor?"
                                        onChange={(e) => setdata({
                                            ...data,
                                            knownVendor: e.target.value
                                        })}
                                        value={data?.knownVendor}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="isVendorCapable"
                                        label="Are you satisfied the Vendor has the capacity and capability to deliver the service required?"
                                        onChange={(e) => setdata({
                                            ...data,
                                            isVendorCapable: e.target.value
                                        })}
                                        value={data?.isVendorCapable}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="reason"
                                        label="What checks were carried out. Please explain"
                                        onChange={(e) => setdata({
                                            ...data,
                                            reason: e.target.value
                                        })}
                                        value={data?.reason}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="relatedParty"
                                        label="Do you have a related party relationship with this Vendor?"
                                        onChange={(e) => setdata({
                                            ...data,
                                            relatedParty: e.target.value
                                        })}
                                        value={data?.relatedParty}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="requesterName"
                                        label="Requester's name / title / department:"
                                        onChange={(e) => setdata({
                                            ...data,
                                            requesterName: e.target.value
                                        })}
                                        value={data?.requesterName}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="requesterSign"
                                        label="Requester Signature"
                                        onChange={(e) => setdata({
                                            ...data,
                                            requesterSign: e.target.value
                                        })}
                                        value={data?.requesterSign}
                                        required
                                    />
                                </div>
                                <div className="mb-3 col-4">
                                    <Input
                                        inputName="date"
                                        label="Date"
                                        onChange={(e) => setdata({
                                            ...data,
                                            date: e.target.value
                                        })}
                                        value={data?.date}
                                        required
                                    />
                                </div>
                            </div>
                            <button type='submit' className='rounded-lg bg-blue-800 text-white w-full p-2 disabled:opacity-50'>Submit</button>
                        </form>
                    }
                </div>
                {forms && forms?.bankdetails >= 2 ?
                    <>
                        <ReactToPrint
                            trigger={() => {
                                return <button className='rounded-lg bg-blue-800 text-white w-full p-2 my-3'>Print</button>
                            }}
                            onBeforeGetContent={() => {
                                document.getElementById('maindiv').classList.remove('glass');
                                document.getElementById('printref').classList.remove('glass');
                                document.getElementById('chequeUpload').classList.add('d-none');
                                document.getElementById('extradiv').classList.add('d-none');
                            }}
                            onAfterPrint={() => {
                                document.getElementById('maindiv').classList.add('glass');
                                document.getElementById('printref').classList.add('glass');
                                document.getElementById('chequeUpload').classList.remove('d-none');
                                document.getElementById('extradiv').classList.add('d-none');
                            }}
                            content={() => printDivRef.current}
                            documentTitle="Vendor Data Form"
                            pageStyle="print"
                        />
                    </>
                    : ""}
            </div>
            <Termsandcondition
                title="Are you sure you want to submit it?"
                subtitle="I hereby declare that all the information given above is true and correct to the best of my knowledge."
                show={showModal}
                setrecall={setrecall}
                onClose={() => setshowModal(false)}
            />
        </>
    )
}

export default InviteForm