import axios from 'axios';
import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { auth, db } from '../../firebase.config';
import { BASE_URL, BASE_URL_LOCAL, updateDocument } from '../../utils/api';
import { showToast } from '../../utils/toast';
import { Spinner } from '../reusable/spinner/Spinner'
import { Input } from '../reusable/input';
import { sendPasswordResetEmail } from 'firebase/auth';

const ForgetPassword = () => {
    const [uid, setuid] = useState();
    const [data, setdata] = useState({});
    const [question, setQuestion] = useState("");
    const [load, setload] = useState(true);
    const _params = useParams();

    useEffect(() => {
        sessionStorage.clear();
        setuid(_params.uid);
        var _tmp;
        onSnapshot(doc(db, "users", _params.uid), (doc) => {
            _tmp = doc.data();
            setdata(_tmp);
            setload(false);
        });
    }, []);

    const handleCheckQuestion = () => {
        try {
            if (question == data?.userid) {
                setload(true);
                handleChangePassword();
            } else {
                showToast('error', "Invalid User ID, please try again");
            }
        } catch (e) {

        }
    }

    const handleChangePassword = () => {
        sendPasswordResetEmail(auth, data?.email)
            .then(() => {
                // showToast('success', `Password Reset Mail send to ${data?.email}`);
                try {
                    window.alert(`Password Reset Mail sent to ${data?.email}`);
                    var myWindow = window.open("about:blank", "_self");
                    myWindow.close();
                } catch (e) {
                    console.log(e);
                }
            })
            .catch((error) => {
                const errorMessage = error.message;
                showToast('warning', errorMessage);
            });
    }

    return (
        <>
            <div className='rounded-lg text-white border-gray-800 d-flex justify-center align-items-center' style={{ height: "100vh" }}>
                {load ? <>
                    <Spinner loader={load} className="top-0" />
                    <p className="h6 pt-20 m-0">Please wait...</p>
                </> :
                    <div className="p-8 pt-0 glass" style={{ width: "450px" }}>
                        <div className='p-3 text-white text-center h3 border-bottom border-secondary break-all'>Forget Password</div>
                        <p className="h6 mb-3 m-0">User: {data?.name}</p>
                        <Input
                            inputName={"question"}
                            label={"Please enter UserID"}
                            onChange={(e) => {
                                setQuestion(e.target.value);
                            }}
                        />
                        <button className='btn btn-primary rounded-lg w-100' disabled={load} onClick={handleCheckQuestion}>Submit</button>
                        <div className="mt-2 break-word bg-white rounded border border-black text-black px-3 py-2"><small><b>Note: </b> {`Please check you email with subject "Welcome ${data?.name} to Onboarding Portal", if not found, please contact Introducer/Vendor Onboarding Team`}</small></div>
                    </div>
                }
            </div>
        </>
    )
}

export default ForgetPassword