import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAtBsdWMtocEQPVS6gCbKLfxaa3wefn3y8",
    authDomain: "omg-vendor-portal.firebaseapp.com",
    projectId: "omg-vendor-portal",
    storageBucket: "omg-vendor-portal.appspot.com",
    messagingSenderId: "458075419072",
    appId: "1:458075419072:web:7f4ea64b17989ad880d825",
    measurementId: "G-ETD1X8L4XC"
};

const app = initializeApp(firebaseConfig, "omgvendorportal");
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

const introducerfirebaseConfig = {
    apiKey: "AIzaSyAtBsdWMtocEQPVS6gCbKLfxaa3wefn3y8",
    authDomain: "omg-vendor-portal.firebaseapp.com",
    databaseURL: "https://omg-vendor-portal-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "omg-vendor-portal",
    storageBucket: "omg-vendor-portal.appspot.com",
    messagingSenderId: "458075419072",
    appId: "1:458075419072:web:c68a3af7e290199080d825",
    measurementId: "G-W9QC1S789H"
};

const introapp = initializeApp(introducerfirebaseConfig, "omgintroducers");
const introauth = getAuth(introapp);


export {
    auth, storage, db, introauth
}